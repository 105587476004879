* {
    padding: 0;
    margin: 0;
}

body {
    font-family: pingfang SC, helvetica neue, arial, hiragino sans gb, microsoft yahei ui, microsoft yahei, simsun,
        sans-serif !important;
}

body,
dl,
dt,
dd,
ul,
ol,
li,
pre,
form,
fieldset,
input,
p,
blockquote,
th,
td,
div {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-family: helvetica neue, Tahoma, Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html,
body,
div,
textarea,
ul,
li,
table,
form {
    scrollbar-width: thin;
}

body {
    color: #666;
    font-size: 14px;
    background-color: #f5f6fa;
    min-width: 1200px;
}

html,
body {
    width: 100%;
    height: 100%;
}

a {
    text-decoration: none;
    color: inherit;
}

// 修改滚动条样式
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: rgba(255, 255, 255, 0);
    /*其他样式，比如圆角等*/
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.2);
}

// 修改antd select滚动条样式
.rc-virtual-list-scrollbar {
    width: 3px !important;
}

.rc-virtual-list-scrollbar-thumb {
    -webkit-border-radius: 2px !important;
    border-radius: 2px !important;
    background-color: rgba(0, 0, 0, 0.2) !important;
}

select {
    font-size: 12px;
}

table {
    border-collapse: collapse;
}

fieldset,
img {
    border: 0 none;
}

fieldset {
    margin: 0;
    padding: 0;
}

fieldset p {
    margin: 0;
    padding: 0 0 0 8px;
}

legend {
    display: none;
}

address,
caption,
em,
strong,
th,
i {
    font-weight: 400;
    font-style: normal;
}

table caption {
    margin-left: -1px;
}

button {
    cursor: pointer;
}

hr {
    clear: both;
    height: 2px;
    margin: 5px 0;
    overflow: hidden;
    border-width: 1px 0;
    border-top: 1px solid #e4e4e4;
    border-bottom: 1px solid #fff;
}

ol,
ul {
    list-style-position: outside;
    list-style-type: none;
    list-style-image: none;
}

li {
    list-style: none;
}

caption,
th {
    text-align: left;
}

q::before,
q::after,
blockquote::before,
blockquote::after {
    content: ””;
}

#root {
    height: 100%;
}

input,
textarea {
    border: none;
    outline: 0;
}

input:-internal-autofill-selected {
    background: none !important;
}
